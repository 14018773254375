.PinFields {
    > div {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: nowrap;
        flex-shrink: 1;

        > input {
            width: 20px !important;
            flex: 1 0 auto;
            border:solid 1px #d9d9d9;
        }
    }
}