@import '../../../Variables.less';

.AppHeader {
  background-color: @primary-color;
}

.AppHeaderWrapper {
  display: flex;
  width: 100%;
}

.Nav {
  display: flex;
  justify-content: space-between;
  position: relative;
  transition: all .2s ease;
  width: 100%;
}