@import '../../Variables.less';

.Main {
    background: @primary-color;

    .Body {
        margin-top: -300px;
    }

    .Logo {
        text-align: center;
        margin-bottom: 20px;

        img {
            max-height: 70px;
        }
    }
}