@import '../../../Variables.less';

.BottomInfo {
    position: fixed;
    bottom: 0;
    width: 250px;
    padding: 15px;
    background-color: @primary-color;
    color: #fff;

    h3 {
        color: #fff;
    }
}