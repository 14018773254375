@import 'Variables.less';

.ant-menu {
    background: @secondary-color;
}

.ant-menu-item, .ant-menu-submenu, .ant-menu-submenu-title, .ant-menu-item-active, .ant-menu-light .ant-menu-item-active {
    color: #fff;

    &:hover {
        color: #fff !important;
    }
}

.ant-menu-inline .ant-menu-item {
    width: 100%;
}

.ant-menu-inline {
    border-right: none;
}

.ant-menu-inline .ant-menu-item::after {
    border-right: none;
    content: none;
}

.ant-menu-sub.ant-menu-inline {
    background: @primary-color;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected, .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-item:hover {
    background-color: #556cb1;
}

.ant-layout-sider {
    background: @secondary-color;
}