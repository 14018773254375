.ProfileInfo {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    justify-content: center;
    align-items: flex-start;
    height:65px;

    span {
        line-height: 1.5715;
    }

    .ProfileName {
        color: #fff;
        font-weight: bold;

        .ProfileLogout {
            cursor: pointer;
            color: #879eff;
        }
    }

    .ProfileLoggedIn {
        color: #e5e5e5;
    }
}